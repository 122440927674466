import * as THREE from "three";
import Logger from './Logger'
import Material from './Material'
import Geometry from './Geometry'

import * as VimeoThree from 'vimeo-threejs-player'

export default class ArchiveObject {
    constructor(scene, object, icon) {
        this.uuid = object._id
        this.icon = icon
        this.children = []
        this.origin = object.position
        this.object = object
        this.scene = scene
        this.isActive = false
        this.defaultMaterial = object.Color ? Material.color(object.Color) :  Material.black()

        icon.position.x = this.origin.x
        icon.position.y = this.origin.y
        icon.position.z = this.origin.z

        this.scene.add(this.icon)
    }

    getId = () => {
        return this.icon.id
    }

    scaleDown = () => {
        this.icon.scale.set(1, 1, 1)
    }

    isFocused = (position) => {
        // Logger.debug('-----------------------')
        // Logger.debug(position.x)
        if (position.x - this.origin.x < 0.0)
            this.icon.material = Material.glass()
        // Logger.debug(this.icon.position.x)
        
        // if (x < 0.0) {
        //     Logger.debug('In focus')
        //     Logger.object('Focused object', this)
        // }
    }

    moveTo = (position) => {
        Logger.warning(position)

        this.isActive = true
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x,
            y: position.y,
            z: position.z - 1.5})

        // Children
        if (this.children[0])
            gsap.to(this.children[0].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x,
                y: position.y,
                z: position.z - 1.5})

        if (this.children[1])        
            gsap.to(this.children[1].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x,
                y: position.y,
                z: position.z - 1.5})
    }


    moveToCenter = () => {
        this.isActive = true
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: 0,
            y: 0,
            z: 0})
    }

    moveToOrigin = () => {
        this.isActive = false
        this.hideContent()
        gsap.to(this.icon.position, { 
            duration: 1.0,
            ease: "elastic",
            // onComplete: this.hideContent,
            x: this.origin.x,
            y: this.origin.y,
            z: this.origin.z
        })
    }

    revealContent = () => {
        // gsap.to(this.icon.scale, { duration: 0.5, ease: "none", x: 0, y: 0, z: 0});
        this.icon.material = Material.glass()
    }

    hideContent = () => {
        // gsap.to(this.icon.scale, { duration: 0.5, ease: "none", x: 1, y: 1, z: 1});
        this.icon.material = this.defaultMaterial
    }

    animate = () => {
        if (!this.isActive) {
            this.icon.rotation.y = this.icon.rotation.y + 0.002
            // this.icon.rotation.x = this.icon.rotation.x + 0.005
        }
    }

    loaded = () => {
        return true
    }

    show = () => {
        this.icon.visible = true
    }

    hide = () => {
        this.icon.visible = false
    }

    clickable = () => {
        return this.icon.visible
    }
}

export class VideoObject extends ArchiveObject {
    constructor(scene, object, icon) {
        super(scene, object, icon)
        this.icon.material = this.defaultMaterial
        if (object.Vimeo != null) {
            // VimeoThree.Player(chapter.Vimeo, { autoplay: true, autoload: true });
            Logger.object('VideoObject', object.Vimeo)
            this.vimeoPlayer = new VimeoThree.Player(object.Vimeo, { autoplay: false, autoload: false, loop: true });
            this.vimeoPlayer.on('videoLoad', this.setTexture);

            // this.children[0] = new THREE.Mesh(Geometry.videoPlane(), null)

            var cubeMaterials = Material.blackBlock()
            this.children[0] = new THREE.Mesh(Geometry.videoBox(), cubeMaterials)
        }
    }

    setTexture = (texture) => {
        // alert("setTexture")
        console.log("VideoObject:" + this.vimeoPlayer)
        // Logger.object('SetTexture', this.children[0])
        // this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
        this.children[0].material[4] = new THREE.MeshBasicMaterial({ map: texture })
        this.vimeoPlayer.play();
        this.videoLoaded = true;
    }

    getIcon = () => {
        return this.icon
    }

    moveTo = (position) => {
        Logger.warning(position)

        this.isActive = true
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x + 0.1,
            y: position.y,
            z: position.z - 2.0})

        // Children
        if (this.children[0])
            gsap.to(this.children[0].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x + 0.1,
                y: position.y,
                z: position.z - 1.2})

        if (this.children[1])        
            gsap.to(this.children[1].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x + 0.1,
                y: position.y,
                z: position.z - 1.2})
    }

    revealContent = () => {
        Logger.info("Playing video...");
        // gsap.to(this.icon.scale, { duration: 0.5, ease: "none", x: 0, y: 0, z: 0});
        this.icon.material = Material.glass()

        if (this.vimeoPlayer != null) {
            if (!this.videoLoaded) {
                this.vimeoPlayer.load();
            } else {
                this.vimeoPlayer.play();
            }
            this.scene.add(this.children[0])
        }
    }

    hideContent = () => {
        this.icon.material = this.defaultMaterial
        this.vimeoPlayer.pause()
        this.scene.remove(this.children[0])
        // this.scene.remove(this.children[1])
    }

    loaded = () => {
        return this.videoLoaded;
    }
}

export class VideoObject360 extends ArchiveObject {
    constructor(scene, object, icon) {
        super(scene, object, icon)
        this.icon.material = this.defaultMaterial
        if (object.Vimeo != null) {
            // VimeoThree.Player(chapter.Vimeo, { autoplay: true, autoload: true });
            Logger.object('VideoObject', object.Vimeo)
            this.vimeoPlayer = new VimeoThree.Player(object.Vimeo, { autoplay: false, autoload: false, loop: true });
            this.vimeoPlayer.on('videoLoad', this.setTexture);

            // this.children[0] = new THREE.Mesh(Geometry.videoPlane(), null)

            // var cubeMaterials = Material.blackBlock()
            this.children[0] = new THREE.Mesh(Geometry.videoSphere(), Material.black())
        }
    }

    setTexture = (texture) => {
        console.log("VideoObject:" + this.vimeoPlayer)
        // Logger.object('SetTexture', this.children[0])
        // this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
        this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
        this.children[0].material.side = THREE.DoubleSide
        this.vimeoPlayer.play();
        this.videoLoaded = true;
    }

    getIcon = () => {
        return this.icon
    }

    moveTo = (position) => {
        Logger.warning(position)

        this.isActive = true
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x + 0.1,
            y: position.y,
            z: position.z - 2.0})

        // Children
        if (this.children[0])
            gsap.to(this.children[0].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x + 0.1,
                y: position.y,
                z: position.z - 1.2})

        if (this.children[1])        
            gsap.to(this.children[1].position, {
                duration: 1.0,
                ease: "elastic",
                onComplete: this.revealContent,
                x: position.x + 0.1,
                y: position.y,
                z: position.z - 1.2})
    }

    revealContent = () => {
        Logger.info("Playing video...")
        // this.vimeoPlayer.play();
        // gsap.to(this.icon.scale, { duration: 0.5, ease: "none", x: 0, y: 0, z: 0});
        this.icon.material = Material.glass()

        if (this.vimeoPlayer != null) {
            if (!this.videoLoaded) {
                this.vimeoPlayer.load();
            } else {
                this.vimeoPlayer.play();
            }
            this.scene.add(this.children[0])
        }
    }

    hideContent = () => {
        this.icon.material = this.defaultMaterial
        this.vimeoPlayer.pause()
        this.scene.remove(this.children[0])
        // this.scene.remove(this.children[1])
    }

    loaded = () => {
        return this.videoLoaded;
    }
}

export class AudioObject extends ArchiveObject {
    constructor(scene, object, icon, player, font) {
        super(scene, object, icon)
        this.icon.children[0].material = this.defaultMaterial
        this.player = player
        this.buffer = null
        

        // // load a sound and set it as the Audio object's buffer
        const audioLoader = new THREE.AudioLoader();
        const url = 'https://n-note.zirkular.io/' + object.Audio
        audioLoader.load(url, this.storeBuffer);

        this.children[0] = 
            new THREE.Mesh(Geometry.textMedium(font, object.Name), 
                Material.textBlack())
    }

    storeBuffer = (buffer) => {
        Logger.debug('Audio loaded.')
        this.buffer = buffer
    }

    getId = () => {
        return this.icon.id + 1
    }

    getIcon = () => {
        return this.icon
    }

    moveTo = (position) => {
        Logger.warning(position)
        this.isActive = true
        
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x - 2,
            y: position.y - 1.5,
            z: position.z - 4.0})

        gsap.to(this.children[0].position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x - 0.8,
            y: position.y - 0.2,
            z: position.z - 3.0})
    }

    moveToCenter = () => {
        this.isActive = true
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: -2,
            y: -1.5,
            z: -1
        });
    }

    revealContent = () => {
        this.icon.children[0].material = Material.glass()
       
        this.player.setBuffer(this.buffer);
        this.player.setLoop(true);
        this.player.setVolume(1.0);
        this.player.play();

        this.scene.add(this.children[0])
    }

    hideContent = () => {
        this.icon.children[0].material = this.defaultMaterial
        this.player.stop();

        this.scene.remove(this.children[0])
    }

    animate = () => {
        if (!this.isActive)
            this.icon.children[0].rotation.y = this.icon.children[0].rotation.y + 0.002
    }
}

export class PhotoObject extends ArchiveObject {
    constructor(scene, object, icon) {
        super(scene, object, icon)
        Logger.debug(icon)
        this.icon.children[0].material = this.defaultMaterial
        this.icon.scale.set(3, 3, 3)
        this.landscape = false

        // console.log("-----------")
        // console.log(this.icon)

        Logger.object(object.Name, object.Image)


        const url = 'https://n-note.zirkular.io' + object.Image.path
        new THREE.TextureLoader().load(url, this.setPhoto);

        this.children[0] = new THREE.Mesh(Geometry.photoPlanePortrait(), Material.black())
    }

    setPhoto = (texture) => {
        // Logger.object('setPhoto', texture.image.height)
        let width = texture.image.width / 1000.0
        let height = texture.image.height / 1000.0
        if (width > height) {
            this.landscape = true
            this.icon.rotation.set(0, 0, 1.57)
        }
        
        this.children[0].scale.set(width, height, 0)
        this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
    }

    getIcon = () => {
        return this.icon
    }

    getId = () => {
        return this.icon.id + 1
    }

    revealContent = () => {
        this.icon.children[0].material = Material.glass()
        if (this.landscape)
            this.icon.rotation.set(Math.PI, 0, 1.57)
        else
            this.icon.rotation.set(Math.PI, 0, 0)
        this.scene.add(this.children[0])
    }

    hideContent = () => {
        this.icon.children[0].material = this.defaultMaterial
        this.scene.remove(this.children[0])
    }
}

export class DrawingObject extends ArchiveObject {
    constructor(scene, object, icon) {
        super(scene, object, icon)
        Logger.object('Drawingobject', icon)
        this.icon.children[0].material = this.defaultMaterial
        this.icon.scale.set(1.5, 1.5, 1.5)
        this.landscape = false


        const url = 'https://n-note.zirkular.io' + object.Image.path
        new THREE.TextureLoader().load(url, this.setPhoto);

        this.children[0] = new THREE.Mesh(Geometry.photoPlanePortrait(), Material.black())
    }

    setPhoto = (texture) => {
        let width = texture.image.width / 2000.0
        let height = texture.image.height / 2000.0
        if (width > height) {
            this.landscape = true
            // this.icon.rotation.set(0, 0, 1.57)
        }
        
        this.children[0].scale.set(width, height, 0)
        this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
    }

    getIcon = () => {
        return this.icon
    }

    getId = () => {
        return this.icon.id + 1
    }

    moveTo = (position) => {
        Logger.warning(position)
        this.isActive = true
        
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x,
            y: position.y - 0.0,
            z: position.z - 1.2})

        gsap.to(this.children[0].position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x - 0.0,
            y: position.y + 0.2,
            z: position.z - 1.2})
    }

    revealContent = () => {
        this.icon.children[0].material = Material.glass()
        this.scene.add(this.children[0])
    }

    hideContent = () => {
        this.icon.children[0].material = Material.black()
        this.scene.remove(this.children[0])
    }
}

export class NoteObject extends ArchiveObject {
    constructor(scene, object, icon) {
        super(scene, object, icon)
        // this.icon.children[0].material = this.defaultMaterial
        this.icon.scale.set(1.5, 1.5, 1.5)
        this.landscape = false

        // alert(object.Image)
        const url = 'https://n-note.zirkular.io' + object.Image.path
        new THREE.TextureLoader().load(url, this.setPhoto);

        this.children[0] = new THREE.Mesh(Geometry.photoPlanePortrait(), Material.black())
    }

    setPhoto = (texture) => {
        let width = texture.image.width / 2000.0
        let height = texture.image.height / 2000.0
        
        this.children[0].scale.set(width, height, 0)
        this.children[0].material = new THREE.MeshBasicMaterial({ map: texture })
    }

    getIcon = () => {
        return this.icon
    }

    getId = () => {
        return this.icon.id
    }

    moveTo = (position) => {
        Logger.warning(position)
        this.isActive = true
        
        gsap.to(this.icon.position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x - 2.0,
            y: position.y - 0.0,
            z: position.z - 1.21})

        gsap.to(this.children[0].position, {
            duration: 1.0,
            ease: "elastic",
            onComplete: this.revealContent,
            x: position.x - 0.0,
            y: position.y + 0.0,
            z: position.z - 1.2})
    }

    revealContent = () => {
        this.icon.material = Material.glass()
        this.icon.rotation.set(0, 0, 0)
        this.scene.add(this.children[0])
    }

    hideContent = () => {
        this.icon.material = Material.black()
        this.scene.remove(this.children[0])
    }
}