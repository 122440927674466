export default {
    // level: 2,
    level: 2,
    debug: function(debug) {
        if (this.level >= 4)
            console.log('[DEBUG]', debug)
    },
    info: function(info) {
        console.log('[INFO]', info)
    },
    warning: function(warning) {
        console.log('[WARN]', warning)
    },
    error: function(error) {
        console.log('[ERROR]', error)
    },
    // object: function(object) {
    //     console.log('[DEBUG] Object:')
    //     console.log(object)
    // },
    object: function(name, object) {
        if (this.level >= 4) {
            console.log('[DEBUG] Object:', name)
            console.log(object)
        }
        
    }
}