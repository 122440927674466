<template>
  <div id="overlay">
      <img src='826.gif' />
  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    msg: String,
  },
  data: function () {
    return {
    };
  },
  methods: {
    hideOverlay: function() {
      this.$store.commit(
        "SetShowOverlay",
        false
      );
    }
  }
}
</script>

<style>

#overlay {
  position: absolute;
  top: 0;
  color: white;
  background-color: #000000;
  font-size: 2rem;
  padding-top: 50%;
  z-index: 1000;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  /* top: 0px;
  height: 100vh;
  margin: auto; */
}

@media (min-width: 1000px) {
  /* For mobile phones: */
  #overlay {
    padding-top: 20%;
  }
}

#overlay iframe {
  opacity: 1;
}
</style>