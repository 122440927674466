<template>
  <div id="app">
    <Intro v-if="this.$store.state.isIntroVisible" />
    <Menu />
    <Library />
    <!-- <Info /> -->
    <!-- <Controls /> -->
    <transition name="fade">
      <Overlay v-if="!this.$store.state.isLoaded" />
    </transition>
    <Loader v-if="this.$store.state.objectLoading"/>

    <About v-if="this.$store.state.isAboutVisible" />

    

    <div id="center-dummy"></div>
  </div>
</template>

<script>
import axios from 'axios'
import Library from "./components/Library.vue";
import Controls from "./components/Controls.vue";
import Info from "./components/Info.vue";
import Menu from "./components/Menu.vue";
import Overlay from "./components/Overlay.vue";
import Loader from "./components/Loader.vue";
import Intro from "./components/Intro.vue";
import About from "./components/About.vue";

export default {
  name: "App",
  components: {
    Library,
    Controls,
    Info,
    Menu,
    Overlay,
    Loader,
    Intro,
    About
  },
  methods: {
  },
  
  mounted() {
    this.$store.commit("SetIntroFinished", true);
      gsap.delayedCall(1, () => {
        this.$store.commit("SetLoaded", true);
        this.$tours['tour'].start();
      });
  },
};
</script>

<style>
body {
	  margin: 0;
		background-image: linear-gradient(#ffffff, #999999);
}
#app {
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-bottom: 300px; */
}
.left {
  float: left;
}
.content {
  width: 85%;
  margin: 0 auto;
  background-color: white;
  padding: 30px;
  /* height: 80px; */
  min-height: 40px;
}
.v-spacer-large {
  /* height: 80px; */
}
.btn_active,
.not-collapsed {
  background-color: lightsalmon !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: linear;
  transition-delay: 500ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#center-dummy {
  width: 1px;
  height: 1px;
  background-color: red;
  z-index: 0;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  /* margin: 0 auto; */
   /* position: absolute;               2 */
   /* top: 50%;                         3 */
   /* transform: translate(0, -50%);   4 */
}
</style>
