<template>
  <div id="loader">
    <img src="826.gif" />
    <span>{{this.text}}</span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    msg: String,
  },
  data: function () {
    return {
      text: 'Loading'
    };
  },
};
</script>

<style>
#loader {
  /* position: absolute;
  top: 20%;
  margin: 0 auto;
  color: white;
  background-color: #000000;
  font-size: 2rem;
  padding-top: 50%;
  z-index: 1000;
  width: 50%;
  height: 50%;
  text-align: center;
  display: block; */
  opacity: 0.8;
  z-index: 1000;
  display: block;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -150px;
  width: 300px;
  height: 300px;
  background-color: black;
  text-align: center;
  color: white;
  font-size: 2rem;
  
  /* top: 0px;
  height: 100vh;
  margin: auto; */
}

#loader span {
  vertical-align: center;
  margin: -150px auto;
  display: block;
}

#loader img {
  /* margin-top: -300px; */
}


#loader iframe {
  opacity: 1;
}
</style>