<template>
  <div id="about">
    <a class="close" href="#" v-on:click="close">X</a>
    <div v-html="content"></div>
    <!-- <h1>About N.Note</h1>
    <div class="v-spacer" />
    <h3>from the series “Book of visible and invisible Notes”</h3>
    <div class="v-spacer" />
    <p>supported by:</p>
    <p>
      Landesverband Freie Tanz- und Theaterschaffende Baden-Württemberg e.V.
    </p>
    <p>
      NATIONAL PERFORMANCE NETWORK - STEPPING OUT, funded by the Federal
      Government Commissioner for Culture and Media within the framework of the
      initiative NEUSTART KULTUR. Assistance Program for Dance.
    </p>
    <div class="v-spacer" />
    <h3>Credits</h3>
    <div class="v-spacer" />
    <div class="credit-item">
      <p>
        Concept / Artistic & Project Manager / Choreography / Dance-Performance
        / Text / Stage
      </p>
      <p class="bold">Katarzyna Brzezinska</p>
    </div>

    <div class="credit-item">
      <p>Project Manager Support / Public Relation</p>
      <p class="bold">Ewa Kirchner, Linda Brodhag Pilar</p>
    </div>

    <div class="credit-item">
      <p>Music Composition</p>
      <p class="bold">Alex Zampini</p>
    </div>

    <div class="credit-item">
      <p>Mentoring</p>
      <p class="bold">Edan Gorlicki</p>
    </div>

    <div class="credit-item">
      <p>Light</p>
      <p class="bold">Holger Guirsberger</p>
    </div>

    <div class="credit-item">
      <p>Technical Support</p>
      <p class="bold">Tim Weseloch, Miriam Seifert</p>
    </div>

    <div class="credit-item">
      <p>Video / Film / Edit / Teaser</p>
      <p class="bold">Keren Chernizon</p>
    </div>

    <div class="credit-item">
      <p>3D-Design und -Programmierung</p>
      <p class="bold">Erik Kundt</p>
    </div>

    <div class="credit-item">
      <p>Video Documentation</p>
      <p class="bold">Bodo Keiser</p>
    </div>

    <div class="credit-item">
      <p>Photo Documentation</p>
      <p class="bold">Jennifer Rohrbacher</p>
    </div>

    <p>
      in cooperation with : E-WERK Freiburg, Maria Magdalena Kirche Freiburg -
      Riesefeld
    </p> -->
    <a class="button" href="#" v-on:click="close">Close</a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "About",
  props: {
    msg: String,
    content: String,
  },
  data: function () {
    return {};
  },
  methods: {
    close: function () {
      this.$store.commit("SetAboutVisible", false);
    },
  },
  created: function () {
    axios
      .get("https://n-note.zirkular.io/api/singletons/get/About")
      .then((response) => {
        this.content = response.data.Content;
      });
  },
};
</script>

<style>
.bold {
  font-weight: bold;
  margin-left: 1rem;
}
.v-spacer {
  margin-top: 3rem;
}

.credit-item {
  width: 100%;
  height: 40px;
  display: block;
}

.credit-item p {
  display: block;
  float: left;
}

.v-spacer-sm {
  margin-top: 1rem;
}

#about h3 {
  text-align: left;
}

#about {
  position: absolute;
  top: 0;
  color: lightgray;
  background-color: #000000;
  /* font-size: 2rem; */
  /* padding-top: 50%; */
  z-index: 2000;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  padding: 2rem;
  overflow-y: auto;
}

#about .credit-item p {
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  /* For mobile phones: */
  #about {
    padding: 1rem 20% 1rem 20%;
  }

  /* #about .credit-item p {
    width: 100%;
    width: 45%;
  } */
}

#about p,
#about h5 {
  text-align: left;
}

#about .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: orangered;
}

@media (min-width: 1000px) {
  /* For mobile phones: */
  #about {
    padding-top: 5%;
  }
}

#about iframe {
  opacity: 1;
}

#about .button {
  display: block;
  width: 20%;
  height: 60px;
  border: 1px solid lightgray;
  margin: 0 auto;
  color: lightgray;
  padding-top: 10pt;
  margin-top: 5rem;
  margin-bottom: 5rem;
  font-weight: bold;
}
</style>