<template>
  <li class="menu-item">
    <a
      :class="{ bold: isFolder }"
      @click="clickItem(item)"
      @dblclick="makeFolder"
    >
      {{ item.Name }}
      <span v-if="isFolder">[{{ isOpen ? "-" : "+" }}]</span>
    </a>
    <ul v-show="isOpen" v-if="isFolder">
      <MenuItem
        class="item"
        v-for="(child, index) in item.menuChildren"
        :key="index"
        :item="child"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
      ></MenuItem>
      <!-- <li class="add" @click="$emit('add-item', item)">+</li> -->
    </ul>
    <ul v-show="isOpen">
      <!-- <li class="menu-item"><a href="#">Hide others</a></li> -->
    </ul>
  </li>
</template>


<script>
export default {
  name: "MenuItem",
  props: {
    item: Object,
  },
  data: function () {
    return {
      isOpen: false,
    };
  },
  computed: {
    isFolder: function () {
      return this.item.menuChildren && this.item.menuChildren.length;
    },
  },
  methods: {
    clickItem: function (item) {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      } else {
        let aObject = this.$store.getters.objectByUUID(item._id);
        console.log(aObject);
        this.$store.commit("SetActiveObject", aObject);

        if (window.matchMedia("(max-width: 767px)").matches) {
          // The viewport is less than 768 pixels wide
          // document.write("This is a mobile device.");
        }
      }
    },
    makeFolder: function () {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
  },
};
</script>

<style>
.menu-item a:hover {
  cursor: pointer;
}
</style>
