<template>
  <div class="container">
    <div v-bind:class="{ collapsed: this.$store.state.menuCollapsed }" id="menu">
      <!-- <div class="" id="menu"> -->
      <div class="v-spacer" />
      <a v-if="this.$store.state.menuCollapsed" class="close" href="#" v-on:click="closeMenu">🗙</a>
      <h1>N.Note</h1>
      <h2>The Archive</h2>

      <div class="v-spacer" />
      <p class="delimiter"></p>
      <ul>
        <MenuItem
          class="item"
          :item="p1_videos"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <ul>
        <MenuItem
          class="item"
          :item="p1_photos"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <p class="delimiter"></p>
      <ul>
        <MenuItem
          class="item"
          :item="p3_videos"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <ul>
        <MenuItem
          class="item"
          :item="p3_photos"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <ul>
        <MenuItem
          class="item"
          :item="p3_drawings"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <ul>
        <MenuItem
          class="item"
          :item="p3_notes"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>

      <!-- <h3>TEXTS</h3>
    <ul>
      <li v-for="drawing in this.$store.state.texts" :key="drawing.Name">
        <a href="#">{{ drawing.Name }}</a>
      </li>
    </ul> -->

    <p class="delimiter"></p>
      <ul>
        <MenuItem
          class="item"
          :item="p4_videos360"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>

      <p class="delimiter"></p>
      <ul>
        <MenuItem
          class="item"
          :item="p5_audio"
          @make-folder="makeFolder"
          @add-item="addItem"
        ></MenuItem>
      </ul>
      <!-- <p class="delimiter">⸻</p> -->
      <p class="delimiter"></p>
      <a class="about" href="#" v-on:click="startTour">Tour</a>
      <a class="about" href="#" v-on:click="showAbout">About</a>
    </div>
    <div v-if="!this.$store.state.menuCollapsed" class="burger">
      <a class="arrow" href="#" v-on:click="toggleMenu">☰</a>
    </div>
    <a class="close-obj" href="#" v-on:click="closeObject">🗙</a>
    <div class="menu-background">
    </div>

    <v-tour name="tour" :steps="steps"></v-tour>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";

export default {
  name: "Menu",
  components: {
    MenuItem,
  },
  props: {
    msg: String,
    tour: null,
  },
  methods: {
    makeFolder: function (item) {
      Vue.set(item, "menuChildren", []);
      this.addItem(item);
    },
    addItem: function (item) {
      item.menuChildren.push({
        name: "new stuff",
      });
    },
    createTree: function (name, objects) {
      return {
        Name: name,
        menuChildren: objects,
      };
    },
    toggleMenu: function () {
      let collapsed = this.$store.state.menuCollapsed
      this.$store.commit('SetMenuCollapsed', !collapsed)
    },
    openMenu: function () {
      this.$store.commit('SetMenuCollapsed', true)
    },
    closeMenu: function () {
      this.$store.commit('SetMenuCollapsed', false)
    },
    showAbout: function () {
      this.$store.commit('SetAboutVisible', true)
    },
    showIntro: function () {
      this.$store.commit("SetIntroVisible", false);
    },
    startTour: function () {
      // this.$store.commit("SetStartTour", true);
      // this.tour.start();
      this.$store.commit('SetMenuCollapsed', false)
      this.$tours['tour'].start();
    },
    closeObject: function () {
      this.$store.commit('SetActiveObject', null)
    },
  },
  computed: {
    p1_videos: function () {
      return this.createTree("VIDEOS", this.$store.state.videosLive);
    },
    p1_photos: function () {
      return this.createTree("PHOTOS", this.$store.state.photosLive);
    },
    p3_videos: function () {
      return this.createTree("VIDEOS", this.$store.state.videos);
    },
    p3_photos: function () {
      return this.createTree("PHOTOS", this.$store.state.photos);
    },
    p3_drawings: function () {
      return this.createTree("DRAWINGS", this.$store.state.drawings);
    },
    p3_notes: function () {
      return this.createTree("NOTES", this.$store.state.notes);
    },
    p4_videos360: function () {
      return this.createTree("360 VIDEOS", this.$store.state.videos360);
    },
    p5_audio: function () {
      return this.createTree("AUDIO", this.$store.state.sounds);
    },
  },
  data () {
      return {
        steps: [
          {
            target: '#center-dummy',
            content: 
            `Welcome to the N.Note interactive archive!`,
          },
          {
            target: '#center-dummy',  // We're using document.querySelector() under the hood
            // content: 
            // `<ul>
            //   <li>
            //     <img src="icons/click.png" />
            //     <h3>Inspect object</h3>
            //   </li>
            //   <li>
            //     <img src="icons/drag.png" />
            //     <h3>Look around</h3>
            //   </li>
            //   <li>
            //     <img src="icons/pinch.png" />
            //     <h3>Move around</h3>
            //   </li>
            // </ul>`,
            content: 
            `You can click <img src="icons/click.png" /> to focus on an object to reveal its content.`,
            // params: {
            //   placement: ''
            // }
          },
          {
            target: '#center-dummy',
            content: 
            `You can drag <img src="icons/drag.png" /> and look around.`,
          },
          {
            target: '#center-dummy',
            content: 
            `And you can pinch or scroll <img src="icons/pinch.png" /> to move in space.`,
          },
          {
            target: '.close-obj',
            content: 
            `If an object is in focus, you can close it again and continue exploring.`,
          },
          {
            target: '.arrow',  // We're using document.querySelector() under the hood
            content: `Click on the menu to show a list with all objects and get more information on the archive.`
          },
          // {
          //   target: '.v-step-1',
          //   content: 'An awesome plugin made with Vue.js!'
          // },
          // {
          //   target: '[data-v-step="2"]',
          //   content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
          //   params: {
          //     placement: 'top'
          //   }
          // }
        ]
      }
    },
};
</script>

<style>
.text-right {
  text-align: right;
}
.v-spacer {
  margin-top: 3rem;
}

#menu {
  position: absolute;
  display: none;
  /* bottom: 94%; */
  left: 0px;
  height: 100%;
  background-color: #201f1e;
  /* color: #FFF; */
  color: lightgray;
  /* font-size: 0.8rem; */
  z-index: 100;
  /* opacity: 0.7; */
  width: 100%;
  font-size: 1rem;
  color: lightgray;
  overflow-y: scroll;
}
.burger {
  width: 100%;
  height: 6%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #201f1e;
  z-index: 100;
}
@media (min-width: 768px) {
  /* For mobile phones: */
  #menu {
    width: 25%;
  }
  .burger {
    width: 20%;
  }
}
#menu.collapsed {
  display: block;
}
#menu .arrow-container {
  display: block;
  width: 100%;
}
#menu .about {
  color: lightgray;
  font-size: 1.5rem;
  float: left;
  width: 100%;
  text-align: left;
  padding-left: 2rem;
}
.arrow {
  color: lightgray;
  font-size: 2.5rem;
  text-decoration: none;
  display: block;
  /* width: 100%; */
  float: left;
  margin-left: 1rem;
}
.arrow:hover {
  color: white;
  font-size: 2.5rem;
  text-decoration: none;
}
#menu .close {
  position: absolute;
  left: 0.7rem;
  top: 0.7rem;
  color: lightgray !important;
  font-size: 2.2rem;
  font-weight: normal;
  opacity: 1.0;
}

#menu .close:hover {
  color: white;
}

.menu-background {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 6%;
  background-color: #201f1e;
  z-index: 99;
}
#menu h3,
#menu h4 {
  text-align: left;
  padding: 0 1rem 0 1rem;
}
#menu ul {
  list-style-type: none;
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin: 0;
}
#menu ul li {
  width: 100%;
  display: block;
  /* min-height: 1.2rem; */
}
#menu ul li a {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: lightgray;
}
#menu ul li a.large {
  /* width: 100%;
  display: block; */
  /* min-height: 1.2rem; */
}
#menu ul li p {
  padding: 0;
  margin-bottom: 0rem;
}
#menu p.left {
  float: left;
}
#menu p.right {
  float: right;
}
#menu p.bold {
  font-weight: 600;
}
#menu button {
  background-color: transparent;
  border: none;
  color: black;
}
#menu button.left {
  transform: scale(-1, 1);
}
#menu button.right {
  float: right;
}
.delimiter {
  height: 2rem;
  display: block;
  width: 100%;
}
.close-obj {
  position: absolute;
  right: 1rem;
  /* top: 1rem; */
  color: lightgray;
  z-index: 1000;
  font-size: 2.3rem;
  padding: 0;
}
.close-obj:hover {
  color: white;
  text-decoration: none;
}
.v-step {
  /* margin-left: 1rem !important; */
  /* marginleft: 1rem !important; */
  font-size: 1.2rem;
  z-index: 1000;
  background: #201f1e !important;
  opacity: 0.8;
}

.v-step__content img {
  height: 40px;
}

.v-step__button {
  font-size: 1.0rem !important;
}
</style>
