import * as THREE from "three";

export default {
    boxIcon: function() {
        // return new THREE.BoxGeometry(2.0, 2.0, 2.0)
        return new THREE.OctahedronGeometry(1.5)
    },
    sphereIcon: function() {
        // return new THREE.BoxGeometry(2.0, 2.0, 2.0)
        return new THREE.SphereGeometry(1.0, 32, 16)
        // return new THREE.SphereGeometry(5.0, 2.0, 1.0)
    },
    videoPlane: function() {
        return new THREE.BoxGeometry(1.6, 0.9, 0.0)
    },
    photoPlanePortrait: function() {
        return new THREE.BoxGeometry(1.0, 1.0, 0.0)
    },
    videoBox: function() {
        return new THREE.BoxGeometry(1.6, 0.9, 0.2)
    },
    videoSphere: function() {
        return new THREE.SphereGeometry(10.0, 32, 16)
    },
    textSmall: function(font, text) {
        return new THREE.TextGeometry(text, { font: font, size: 0.07, height: 0.01 })
    },
    textMedium: function(font, text) {
        return new THREE.TextGeometry(text, { font: font, size: 0.15, height: 0.01 })
    },
    textLarge: function(font, text) {
        return new THREE.TextGeometry(text, { font: font, size: 1.0, height: 0.01 })
    }
}