<template>
  <div id="controls" v-if="this.$store.state.isLoaded">
    <!-- <h3 class="text-right">Controls</h3> -->
    <ul>
      <!-- <li>
        <p class="left bold">Scroll story</p>
        <p class="right">Scroll up / down</p>
      </li>
      <li>
        <p class="left bold">Look around</p>
        <p class="right">Mouse drag left / right</p>
      </li> -->
      <li>
        <button class="left" v-on:click="prevChapter()">➫</button>
        <button class="right" v-on:click="nextChapter()">➫</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Controls",
  props: {
    msg: String,
  },
  data: function () {
    return {
    };
  },
  methods: {
    prevChapter: function() {
      let chapter = this.$store.state.currentChapter - 1
      this.$store.commit("SetCurrentChapter", Math.max(0, chapter));
    },
    nextChapter: function() {
      let chapter = this.$store.state.currentChapter + 1
      this.$store.commit(
        "SetCurrentChapter",
        Math.min(this.$store.state.chapters.length - 1, chapter)
      );
    },
  }
}
</script>

<style>
.text-right {
  text-align: right;
}
#controls {
  position: absolute;
  bottom: 0px;
  right: 25%;
  /* color: #FFF; */
  color: #8b008b;
  /* font-size: 0.8rem; */
  z-index: 100;
  /* opacity: 0.7; */
  width: 50%;
  font-size: 5rem;
  /* font-family: 'Rock Salt', cursive; */
  /* top: 0px;
  height: 100vh;
  width: 100%;
  margin: auto; */
}
@media (min-width: 1000px) {
  /* For mobile phones: */
  #controls {
    font-size: 10rem;
    right: 35%;
    width: 30%;
  }
}
#controls ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#controls ul li {
  width: 100%;
  display: block;
  min-height: 1.2rem;
}
#controls ul li p {
  padding: 0;
  margin-bottom: 0rem;
}
#controls p.left {
  float: left;
}
#controls p.right {
  float: right;
}
#controls p.bold {
  font-weight: 600;
}
#controls button {
  background-color: transparent;
  border: none;
  color: black;
}
#controls button.left {
  transform: scale(-1, 1);
}
#controls button.right {
  float: right;
}
</style>
