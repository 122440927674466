<template>
  <div id="scene"></div>
</template>

<script>
import axios from 'axios'
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FirstPersonControls } from "three/examples/jsm/controls/FirstPersonControls";
import { FlyControls } from "three/examples/jsm/controls/FlyControls";
import { DragControls } from "three/examples/jsm/controls/DragControls";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js';
import { UnrealBloomPass  } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import Stats from 'three/examples/jsm/libs/stats.module.js';

// import CameraControls from 'camera-controls';
import LineFigure from "./LineFigure";

import * as hash from 'object-hash'
import { ArchiveObject, AudioObject, VideoObject, VideoObject360, PhotoObject, DrawingObject, NoteObject } from '../lib/ArchiveObject'

import Material from '../lib/Material'
import Geometry from '../lib/Geometry'
import Logger from '../lib/Logger'
export default {
  name: "Library",
  props: {
    msg: String,
  },
  data: function () {
    return {
      url_videos_360: 'https://n-note.zirkular.io/api/collections/get/Videos360',
      url_videos_live: 'https://n-note.zirkular.io/api/collections/get/VideosLive',
      url_videos: 'https://n-note.zirkular.io/api/collections/get/Videos',
      url_sounds: 'https://n-note.zirkular.io/api/collections/get/Sounds',
      url_photos: 'https://n-note.zirkular.io/api/collections/get/Photos',
      url_photos_live: 'https://n-note.zirkular.io/api/collections/get/PhotosLive',
      url_drawings: 'https://n-note.zirkular.io/api/collections/get/Drawings',
      url_notes: 'https://n-note.zirkular.io/api/collections/get/Notes',
      mouseX: 0,
      mouseY: 0,
      mouseDown: false,
      clock: new THREE.Clock(),
      delta: 0,
      interval: 1 / 25,
      stats: null,
      mouseOffsetX: 0,
      mouseOffsetY: 0,
      pointer: {
        x: 0,
        y: 0
      },
      font: null,
      environmentObjectName: "BezierCurve002",
      INTERSECTED: null,
      cameraControls: null,
      initialCameraOffset: 3,
      controls: null,
      raycaster: new THREE.Raycaster(),
      audioPlayer: null,
      mouse: new THREE.Vector2(),
      objects: [],
      env: null,
      offset_y: 0, // 15
      vimeoVideos: [],

      // store: this.$store,
      // state: this.$store.state,

      postprocessing: {}
    };
  },
  methods: {
    initPostprocessing: function() {
      const width = this.container.clientWidth
      const height = this.container.clientHeight

      const bloomPass = new UnrealBloomPass(new THREE.Vector2(width, height), 1.5, 0.4, 0.85);
      // bloomPass.threshold = params.bloomThreshold;
      // bloomPass.strength = params.bloomStrength;
      // bloomPass.radius = params.bloomRadius;


      const renderPass = new RenderPass(this.scene, this.camera)
      const bokehPass = new BokehPass(this.scene, this.camera, {
        focus: 10,
        aperture: 0.7,
        maxblur: 0.0020,

        width: width,
        height: height
      })

      const composer = new EffectComposer(this.renderer);

      composer.addPass(renderPass);
      composer.addPass(bloomPass)
      // composer.addPass(bokehPass);

      this.postprocessing.composer = composer;
      this.postprocessing.bokeh = bokehPass;
      this.postprocessing.bloom = bloomPass;
    },
    extractIdFromTexture: function(name) {
      let parts = name.split('-')
      return parts[parts.length - 1]
    },
    loadChapters: function(font) {
      this.font = font
      axios.get(this.url_videos).then(response => {
        this.$store.commit('SetVideos', response.data.entries)
      })
      axios.get(this.url_videos_live).then(response => {
        this.$store.commit('SetVideosLive', response.data.entries)        
      })
      axios.get(this.url_videos_360).then(response => {
        this.$store.commit('SetVideos360', response.data.entries)        
      })
      axios.get(this.url_sounds).then(response => {
        this.$store.commit('SetSounds', response.data.entries)
      })
      axios.get(this.url_photos).then(response => {
        this.$store.commit('SetPhotos', response.data.entries)
      })
      axios.get(this.url_photos_live).then(response => {
        this.$store.commit('SetPhotosLive', response.data.entries)
      })
      axios.get(this.url_drawings).then(response => {
        this.$store.commit('SetDrawings', response.data.entries)
      })
      axios.get(this.url_notes).then(response => {
        this.$store.commit('SetNotes', response.data.entries)
      })
    },
    loadSoundIcons: function() {
      const gltf_loader = new GLTFLoader();
      const storeMusicIcon = (gltf) => {
        Logger.debug('Loaded music icon.')
        this.$store.commit('SetMusicIcon', gltf)
      }
      gltf_loader.load(
        this.$store.state.dropModel,
        storeMusicIcon,
        function(xhr) { },
        function(error) {
          Logger.error('An error happened while loading drop models.')
          Logger.object('Error', error)
        }
      );
    },
    loadVideoIcons: function() {
      this.$store.commit('SetVideoIcon', {})
    },
    loadVideoLiveIcons: function() {
      this.$store.commit('SetVideoLiveIcon', {})
    },
    loadVideo360Icons: function() {
      this.$store.commit('SetVideo360Icon', {})
    },
    loadPhotoIcons: function() {
      const gltf_loader = new GLTFLoader();
      const storePhotoIcon = (gltf) => {
        Logger.debug('Loaded photo icon.')
        this.$store.commit('SetPhotoIcon', gltf)
      }
      gltf_loader.load(
        this.$store.state.frameModel,
        storePhotoIcon,
        function(xhr) { },
        function(error) {
          Logger.error('An error happened while loading photo models.')
          Logger.object('Error', error)
        }
      );
    },
    loadDrawingIcons: function() {
      const gltf_loader = new GLTFLoader();
      const storeDrawingIcon = (gltf) => {
        Logger.debug('Loaded drawing icon.')
        this.$store.commit('SetDrawingIcon', gltf)
      }
      gltf_loader.load(
        this.$store.state.drawingModel,
        storeDrawingIcon,
        function(xhr) { },
        function(error) {
          Logger.error('An error happened while loading drawing models.')
          Logger.object('Error', error)
        }
      );
    },
    loadNoteIcons: function() {
      this.$store.commit('SetNoteIcon', {})
    },
    updateObjectPositions: function(objects) {
      const counter = this.$store.state.objectPosition
      if (objects !== undefined && objects.length > 0)
        objects.forEach(object => {
          object.position = { 
            x: Math.random() * 25.0 - Math.random() * 20.0, 
            y: Math.random() * 35.0 - Math.random() * 20.0, 
            z: Math.random() * 25.0 - Math.random() * 30.0 
          }
          // Logger.debug('----------')
          // Logger.debug(this.$store.state.positions.length)
          // Logger.debug(this.$store.state.objectsPositioned)
          // Logger.debug('----------')
          // object.position = this.$store.getters.objectPosition(counter)
          this.$store.commit('UpdateObjectsPositioned')
        })
    },
    addEnvironment: function(gltf) {
      gltf.scene.scale.set(8, 8, 8)
      gltf.scene.position.y = -5
      gltf.scene.position.z = -5
      gltf.scene.rotation.x = 2
      

      this.env = gltf.scene
      this.scene.add(gltf.scene);
      // console.log(gltf.scene)

      gltf.animations; // Array<THREE.AnimationClip>
      gltf.scene; // THREE.Group
      gltf.scenes; // Array<THREE.Group>
      gltf.cameras; // Array<THREE.Camera>
      gltf.asset; // Object
    },
    setupLights: function() {
      // const pointLight = new THREE.PointLight(0xAAFFFF, 10);
      // pointLight.position.set(0, 10, -10);
      // pointLight.castShadow = true;
      // pointLight.shadow.mapSize.width = 2048; // default
      // pointLight.shadow.mapSize.height = 2048; // default
      // pointLight.shadow.camera.near = 1; // default
      // pointLight.shadow.camera.far = 1000; // default
      // this.scene.add(pointLight);

      // const light = new THREE.AmbientLight(0xFFFFFF, 1.0); // soft white light
      // this.scene.add(light);

      // var fogColor = new THREE.Color(0xCCCCCC);
      // this.scene.fog = new THREE.Fog(fogColor, 0.1, 0.2);
      // this.scene.background = fogColor;


      // this.scene.fog = new THREE.Fog(0x000000, 1, 15000);
      this.scene.background = new THREE.Color(0xefd1b5);
			// this.scene.fog = new THREE.FogExp2(0xefd1b5, 0.05);
      this.scene.fog = new THREE.FogExp2(0xefd1b5, 0.03);
      // this.scene.fog = new THREE.FogExp2(0x11d23d, 0.03);
      
      // const pointLight = new THREE.PointLight(0xff2200);
      const pointLight = new THREE.PointLight(0xffffff, 1.0);
			pointLight.position.set(0, 0, 0);
			this.scene.add(pointLight);

      const dirLight = new THREE.DirectionalLight(0xefefef);
      dirLight.position.set(0, 0, 1).normalize();
      this.scene.add(dirLight);
    },
    setupAudio: function() {
      // create an AudioListener and add it to the camera
      const listener = new THREE.AudioListener();
      this.camera.add(listener);

      // create a global audio source
      this.audioPlayer = new THREE.Audio(listener);

    },
    createWall: function (chapter) {
      const geo_wall_box = new THREE.BoxGeometry(4, 2, 0.3);
      // const wireframe = new THREE.WireframeGeometry(geometry);
      const mat_glass = MaterialFactory.glass()

      let boxes = []
      
      let box = new THREE.Mesh(geo_wall_box, mat_glass)
        
      box.rotation.x = chapter.rotation.x
      box.rotation.y = chapter.rotation.y
      box.position.set(chapter.position.x, chapter.position.y, chapter.position.z)
      
      boxes.push(box)

      boxes.forEach(box => {
        this.scene.add(box);
      })
      
      return [boxes]
    },
    createText: function(font, text, x, y, z) {
      const geometry = Geometry.textLarge(font, text)
      const materials = Material.textBlack();
      const textMesh = new THREE.Mesh(geometry, materials);

      textMesh.position.set(x - 0.8, y - this.offset_y, z - 0.5); 
      this.scene.add(textMesh);

      return textMesh;
    },
    createDescription: function(chapter, font) {
      const text = chapter.description
      const x = chapter.position.x
      const y = chapter.position.y - 0.7
      const z = chapter.position.z + 0.6

      const geometry = Geometry.textSmall(font, text);

      let materials = []
      if (this.$store.state.drawWalls) {
        materials = [
            new THREE.MeshBasicMaterial({ color: 0xbbbbbb }), // front
            new THREE.MeshBasicMaterial({ color: 0xaaaaaa }) // side
        ];
      } else {
        materials = Material.textBlack();
      }
      const textMesh = new THREE.Mesh(geometry, materials);
      

      textMesh.rotation.x = chapter.rotation.x
      textMesh.rotation.y = chapter.rotation.y
      if (chapter.rotation.x != 0 || chapter.rotation.y != 0) {
        textMesh.position.set(x - 0.6, y, z - 0.9); 
      } else {
        textMesh.position.set(x - 0.8, y, z - 0.5); 
      }
      
      this.scene.add(textMesh);

      return textMesh;
    },
    createAudioObjects: function() {
      const sounds = this.$store.state.sounds

      Logger.info('Creating audio objects...')
      sounds.forEach(sound => {
          Logger.object('musicIcon', this.$store.state.musicIcon.scene)
          let icon = this.$store.state.musicIcon.scene.clone()
          const aObject = new AudioObject(this.scene, sound, icon, this.audioPlayer, this.font)
          this.$store.commit('AddArchiveObject', aObject)
      })
    },
    createVideoObjects: function() {
      const videos = this.$store.state.videos

      Logger.info('Creating video objects...')
      videos.forEach(video => {
        let icon = new THREE.Mesh(Geometry.boxIcon(), Material.black())
        const aObject = new VideoObject(this.scene, video, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    createLiveVideoObjects: function() {
      const videosLive = this.$store.state.videosLive

      Logger.info('Creating live video objects...')
      videosLive.forEach(video => {
        let icon = new THREE.Mesh(Geometry.boxIcon(), Material.black())
        const aObject = new VideoObject(this.scene, video, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    create360VideoObjects: function() {
      const videos360 = this.$store.state.videos360

      Logger.info('Creating 360 video objects...')
      // alert('create360VideoObjects')
      videos360.forEach(video => {
        let icon = new THREE.Mesh(Geometry.sphereIcon(), Material.black())
        const aObject = new VideoObject360(this.scene, video, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    createPhotoObjects: function() {
      const photos = this.$store.state.photos
      const photosLive = this.$store.state.photosLive

      Logger.info('Creating photo objects...')
      photos.forEach(photo => {
        let icon = this.$store.state.photoIcon.scene.clone()
        const aObject = new PhotoObject(this.scene, photo, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })

      photosLive.forEach(photo => {
        let icon = this.$store.state.photoIcon.scene.clone()
        const aObject = new PhotoObject(this.scene, photo, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    createDrawingObjects: function() {
      const drawings = this.$store.state.drawings

      Logger.info('Creating drawing objects...')
      drawings.forEach(drawing => {
        let icon = this.$store.state.drawingIcon.scene.clone()
        const aObject = new DrawingObject(this.scene, drawing, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    createNoteObjects: function(font) {
      const notes = this.$store.state.notes

      Logger.info('Creating note objects...')
      Logger.object('notes', notes)
      notes.forEach(note => {
        let display = (note.Display !== undefined || note.Display == "") ? note.Display : "Note" 
        let icon = new THREE.Mesh(Geometry.textLarge(this.font, display), Material.textBlack())
        const aObject = new NoteObject(this.scene, note, icon)
        
        this.$store.commit('AddArchiveObject', aObject)
      })
    },
    updateCameraOrbit: function() {
        const forward = new THREE.Vector3();
        this.camera.getWorldDirection(forward);

        this.controls.target.copy(this.camera.position).add(forward);
    },
    initScene: function () {
      this.container = document.getElementById("scene");
      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(
        75,
        this.container.clientWidth / this.container.clientHeight,
        1,
        1100
      );
      this.camera.position.set(0, 0, 3);
      this.camera.lookAt(0, 0, 0);

      

      const gltf_loader = new GLTFLoader();
      gltf_loader.load(
        this.$store.state.structure,
        this.addEnvironment,
        function (xhr) {},
        function (error) { console.log('An error happened while loading GLTF of main structure.') }
      );

      // Add background sphere 
      // this.scene.background = new THREE.Color(0xbbbbbb)

      // const geo_equi = new THREE.SphereGeometry(500, 60, 40);
			// // invert the geometry on the x-axis so that all of the faces point inward
			// geo_equi.scale(-1, 1, 1);
      // // const tex_equi = new THREE.TextureLoader().load('wildtextures-handmade-warm-flower-paper-.jpg' );
			// const mat_equi = new THREE.MeshBasicMaterial( { color: 0xbbbbbb } )
      // const mesh = new THREE.Mesh(geo_equi, mat_equi);
      // this.scene.add(mesh);

      // Setup content: Chapters are bound to font loaded
      const loader = new THREE.FontLoader();
      loader.load('Raleway_Bold.json', this.loadChapters);

      // lights & Fog
      this.setupLights();

      // Setup global audio sink
      this.setupAudio()


      // Renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      // this.renderer.shadowMap.enabled = true;
      // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      // Look around controls
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.zoomSpeed = 2.0
      this.controls.addEventListener('end', () => {
        this.updateCameraOrbit();
      });
      this.updateCameraOrbit();

      // Post-processing
      this.initPostprocessing();


      this.container.appendChild(this.renderer.domElement);            
    },

    animate: function () {
      requestAnimationFrame(this.animate);
      // this.objects.length != this.$store.state.chapters.length
      if (!this.$store.state.isLoaded ) {
        return;
      }
      var slowMo = this.$store.state.slowMo;
      var interval = 1 / (slowMo ? 25 : 50);

      this.delta += this.clock.getDelta();
      // this.cameraControls.update(this.delta);

      if (this.delta > interval) {
        this.$store.commit("SetCurrentFrame", this.$store.state.currentFrame + 1);
        this.delta = this.delta % interval;
      }

      // TODO: activate again
      // let activeObject = this.$store.state.activeObject
      // if (activeObject != null)
      //   activeObject.scaleDown()


      // Renders scene. Color clearing not need because of CSS background 
      // and WebGL alpha rendering.
      // this.renderer.setClearColor(0xFFFFFF)
      let activeObject = this.$store.state.activeObject
      if (activeObject) {
          this.$store.commit("SetObjectLoading", !activeObject.loaded());
      }

      this.$store.getters.objects().forEach(aObject => {
        aObject.animate()

        if (activeObject) {
          aObject.hide()
          if (activeObject instanceof VideoObject360) {
            activeObject.hide()
          } else {
            activeObject.show()
          }
        } else {
          aObject.show()
        }
        // console.log(aObject)
        // console.log(aObject.loaded())
        // this.$store.commit("SetObjectLoading", !aObject.loaded());
      })

      this.stats.update()

      this.renderer.render(this.scene, this.camera);
      // this.postprocessing.composer.render(0.1);
      // this.postprocessing.composer.render()
    },
    objectClicked: function(object) {
      Logger.object('clicked.object.id', object.id)
      let aObject = this.$store.getters.objectByChild(object.id)
      aObject = aObject === undefined ? this.$store.state.activeObject : aObject
      Logger.object('clicked.aObject', aObject)

      // if (aObject == this.$store.state.activeObject) {
      //   this.$store.commit('SetActiveObject', null)
      // } else {
      //   this.$store.commit('SetActiveObject', aObject)
      // }
      if (aObject.clickable()) {
        this.$store.commit("SetObjectLoading", true)
        this.$store.commit('SetActiveObject', aObject)
      }
      
    },
    onDataChanged() {
      this.$store.commit("SetAnimationStatus", false);
    },
    handleSceneClick(pointer) {
      if (this.$store.state.activeObjectClick) {
        this.raycaster.setFromCamera(pointer, this.camera)
        const intersects = this.raycaster.intersectObjects(
          this.scene.children,
          true
        )
        if (intersects.length > 0) {
          let object = intersects[0].object
          if (object.name != this.environmentObjectName) {
            this.objectClicked(object)
          }
        }
      }
    }
  },
  mounted() {
    this.initScene();
    this.animate();

    this.stats = new Stats();
    // this.stats.dom.style = "position: fixed; bottom: 0; left: 0;"
		// this.container.appendChild(this.stats.dom);
    

    // window.addEventListener('mousemove', (event) => {
    //   this.pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
		// 	this.pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    // })

    this.container.addEventListener('click', (event) => {
      let pointer = {}
      pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
			pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
      this.handleSceneClick(pointer);
    })

    this.container.addEventListener('touchstart', (event) => {
      let pointer = {}
      pointer.x = ( event.touches[0].clientX / window.innerWidth ) * 2 - 1;
			pointer.y = - ( event.touches[0].clientY / window.innerHeight ) * 2 + 1;
      this.handleSceneClick(pointer);
    })

    window.addEventListener('keydown', (event) => {
      if(event.key === "Escape") {
        this.$store.commit('SetActiveObject', null)
      }
    })

    // window.addEventListener('dblclick ', (event) => {
    //   this.$store.commit('SetActiveObject', null)
    // })

    window.addEventListener('resize', () => {
      this.renderer.setSize(window.innerWidth, window.innerHeight );
    })
  },
  watch: {
    '$store.state.currentChapter': function (newChapter, oldChapter) {
      Logger.debug('Watcher: Current chapter')
      this.gotoChapter(newChapter, 5.0)
    },
    '$store.state.activeObject': function (newObject, oldObject) {
      Logger.debug('Watcher: Active object')
      if (oldObject != null)
      // if (oldObject != null && newObject !== undefined)
        oldObject.moveToOrigin()
      Logger.object('activeObject.controls', this.controls)
      if (newObject != null)
        newObject.moveTo(this.controls.object.position)
    },
    '$store.state.musicIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Music icon')
      this.createAudioObjects()
    },
    '$store.state.videoIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Video icon')
      this.createVideoObjects()
    },
    '$store.state.videoLiveIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Video live icon')
      this.createLiveVideoObjects()
    },
    '$store.state.video360Icon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Video 360 icon')
      this.create360VideoObjects()
    },
    '$store.state.photoIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Frame icon')
      this.createPhotoObjects()
    },
    '$store.state.drawingIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Drawing icon')
      this.createDrawingObjects()
    },
    '$store.state.noteIcon': function (newIcon, oldIcon) {
      Logger.debug('Watcher: Note icon')
      this.createNoteObjects()
    },
    '$store.state.videos': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadVideoIcons()
    },
    '$store.state.videosLive': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadVideoLiveIcons()
    },
    '$store.state.videos360': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadVideo360Icons()
    },
    '$store.state.sounds': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadSoundIcons()
    },
    '$store.state.photos': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadPhotoIcons()
    },
    '$store.state.photosLive': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadPhotoIcons()
    },
    '$store.state.drawings': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadDrawingIcons()
    },
    '$store.state.notes': function (newData, oldData) {
      this.updateObjectPositions(newData)
      this.loadNoteIcons()
    },
  },
};
</script>


<style>
#scene {
  position: relative;
  top: 0px;
  height: 100vh;
  width: 100%;
  margin: auto;
}
</style>
