<template>
  <div id="intro">
    <!--iframe src="https://player.vimeo.com/video/355120249?h=692fb0985f&color=ffffff" width="1280" height="720" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; gyroscope; accelerometer" allowfullscreen></iframe-->
    <!-- <a class="close" href="#" v-on:click="start">X</a> -->

    <!-- <div v-html="content"></div> -->

    <div id="header">
      <h1>N.Note</h1>
    </div>

    <!-- <h1>Welcome to the virtual 'N.Note' archive</h1> -->
    
    <h2>Virtual Archive</h2>

    <!-- <h4>How to interact:</h4> -->

    <ul>
      <li>
        <img src="icons/click.png" />
        <h3>Inspect object</h3>
      </li>
      <li>
        <img src="icons/drag.png" />
        <h3>Look around</h3>
      </li>
      <li>
        <img src="icons/pinch.png" />
        <h3>Move around</h3>
      </li>
      <li>
        <img src="icons/menu.png" />
        <h3>Open menu</h3>
      </li>
    </ul>

    <!-- <h2>Welcome to the virtual 'N.Note' archive</h2>
    <div class="v-spacer" />
    <h5>Here's how you interact with it:</h5>
    <div class="v-spacer-sm" />
    
    <p>ᐅ You can look around by dragging your cursor or on your screen</p>
    <p>ᐅ Clicking or touching an object will reveal its contents</p>
    <p>ᐅ Scrolling or pinching moves you through space</p>
    <p>ᐅ You can view a list of all objects by collapsing the menu</p> -->

    <a class="button" href="#" v-on:click="start">Explore</a>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Intro",
  props: {
    msg: String,
    content: String,
  },
  data: function () {
    return {};
  },
  methods: {
    start: function () {
      this.$store.commit("SetIntroFinished", true);
      gsap.delayedCall(0, () => {
        this.$store.commit("SetLoaded", true);
      });
    },
  },
  created: function () {
    axios
      .get("https://n-note.zirkular.io/api/singletons/get/Intro")
      .then((response) => {
        this.content = response.data.Content;
      });
  },
};
</script>

<style>
.v-spacer {
  margin-top: 3rem;
}

.v-spacer-sm {
  margin-top: 1rem;
}

#intro {
  position: absolute;
  top: 0;
  color: #222222;
  /* background-color: #efd1b5; */
  opacity: 0.5;
  /* font-size: 2rem; */
  padding-top: 50%; 
  z-index: 2000;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  padding: 2rem;
}

#intro .close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: orangered;
}

@media (min-width: 1080px) {
  /* For mobile phones: */
  #intro {
    padding: 1rem 20% 1rem 20%;
  }
}


@media (min-width: 768px) {
  /* For mobile phones: */
  #intro {
    padding: 1rem 20% 1rem 20%;
  }
}

#header {
  position: absolute;
  display: block;
  /* bottom: 94%; */
  left: 0px;
  top: 0;
  height: 3.5rem;
  background-color: #201f1e;
  /* color: #FFF; */
  color: lightgray;
  /* font-size: 0.8rem; */
  z-index: 100;
  /* opacity: 0.7; */
  width: 100%;
  font-size: 1rem;
  color: lightgray;
  overflow-y: scroll;
}

#header h1 {
  float: left;
  margin-left: 1rem
}


#intro p,
#intro h5 {
  text-align: left;
}

#intro iframe {
  opacity: 1;
}


#intro ul {
  list-style-type: none;
  min-height: 50%;
  margin-top: 20%;
}
#intro ul li {
  float: left;
  width: 49%;
  margin-right: 1%;
  margin-top: 3rem;
}
#intro .button {
  display: block;
  width: 40%;
  margin: 0 30%;
  margin-top: 3rem;
  /* width: 20%;
  height: 60px;
  border: 2px solid #222222;
  margin: 0 auto;
  color: lightgray; */
  height: 60px;
  color: lightgray;
  background-color: #222222;
  padding-top: 0.8rem;
  font-size: 1.5rem;
  /* border: 2px soli; */
  /* padding-top: 12pt;
  margin-top: 5rem;
  margin-bottom: 5rem; */
  /* border: 2px solid #222222; */
  font-weight: bold;
}
iframe {
  position: absolute;
  top: 5%;
  left: 12%;
}
</style>